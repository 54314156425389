
import { reactive, onMounted, ref } from 'vue'
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import axios from "@/plugin/vue-axios";
import { useStore } from "vuex";

import ReumTitle from '@/components/ReumLib/layout/ReumTitle.vue'
import ReumTextBox from '@/components/ReumLib/UI/ReumTextBox.vue'

import genoplan_status from '@/components/js/genoplan_status.js'

export default {
    name: 'Home',
    components: {
        ReumTitle,
        ReumTextBox
    },
    props: {
        id: {
            type: [Number, String],
            default: -1,
        },
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const store = useStore();
        const gelImage = ref(null);
        const gelImagePreview = ref(null);
        const xlsFile = ref(null);

        const options = {
            status: [
                {value: "DNA Prep 완료", label:"DNA Prep 완료"},
                {value: "DNA 재추출", label:"DNA 재추출"},
                {value: " 검체 재요청", label:" 검체 재요청"},
            ],
            status_qc: [
                {value: "", label:"미입력"},
                {value: "PASS", label:"PASS", color:"#00F"},
                {value: "FAIL", label:"FAIL", color:"#F00"},
            ],
            gender : [
                {value: "", label:"미입력"},
                {value: "M", label:"남성"},
                {value: "F", label:"여성"},
            ]
        }

        const sampleCalc = reactive({
            libraryPoolingPM : () => {
                let rtn = Math.round(parseFloat(comp.plateinfo.nm)/5*1000);
                return isNaN(rtn)?"-":rtn;
            },
            libraryPoolingSize : (tapestation_bp) => {
                let rtn = Math.round(parseFloat(tapestation_bp)*650);
                return isNaN(rtn)?"-":rtn;
            },
            libraryPoolingNM : (qubit, tapestation_bp) => {
                let rtn = Math.round((parseFloat(comp.plateinfo.nm) * 40) / (qubit/sampleCalc.libraryPoolingSize(tapestation_bp) * 1000000)*100)/100;
                return isNaN(rtn)?"-":rtn;
            },
            libraryPoolingDW : (qubit, tapestation_bp) => {
                let rtn = 40 - sampleCalc.libraryPoolingNM(qubit, tapestation_bp);
                return isNaN(rtn)?"-":rtn;
            }
        });

        const sample = reactive({
            files: null,
            list: [{id_after:null, id_before:"", sn:"", sn_after:"", well_no: "",  dna_conc:"", qc:"", status:""}],
            excelcheck : () => {
                let formData = new FormData();
                if(xlsFile.value.files.length<1) {
                    toast.error("파일을 첨부해주세요.");
                    return;
                }
                formData.append("nfile", xlsFile.value.files[0]);

                axios.post("/api/common/xlsxReader", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    if(res.data.err == 0) {
                        console.info(res.data);
                        if(res.data.data[1][0]!="ID" || res.data.data[1][1]!="실험일" || res.data.data[1][2]!="Batch ID" || res.data.data[1][7]!="I7 index") {
                            toast.error("양식이 일치하지 않습니다.");
                            return;
                        }
                        let check = true;
                        for(let i=3;i<res.data.data.length;i++) {
                            if(sample.list[i-3].id == res.data.data[i][0]) {
                                check = false;
                                sample.list[i-3].exp_date = res.data.data[i][1];
                                sample.list[i-3].dna_batch_id = res.data.data[i][2];
                                sample.list[i-3].sn = res.data.data[i][3];
                                sample.list[i-3].pooling_no = res.data.data[i][4];
                                sample.list[i-3].pcr_cycle = res.data.data[i][9];
                                sample.list[i-3].elution_vol = res.data.data[i][10];
                                sample.list[i-3].qubit = res.data.data[i][11];
                                sample.list[i-3].tapestation = res.data.data[i][12];
                                sample.list[i-3].tapestation_bp = res.data.data[i][13];
                                sample.list[i-3].qc = res.data.data[i][17];
                                sample.list[i-3].status = res.data.data[i][19];
                                sample.list[i-3].memo = res.data.data[i][20];
                            }
                        }
                        if(check) {
                            toast.error("일치된 ID가 없어 데이터를 반영 할 수 없습니다.");
                        } else {
                            toast.success("데이터를 불러왔습니다.");
                        }
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
            excelupload: () => {
                xlsFile.value.click();
            },
            getExcelSample: () => {
                location.href="/rest/api/common/getExcelSample?sample=ExomeCapture&id=" + comp.id;
            },
            exceldown : () => {
                let schema = [
                    {key: "id"},
                    {key: "exp_date"},
                    {key: "dna_batch_id"},
                    {key: "sn"},
                    {key: "pooling_no"},
                    {key: "udi_plate"},
                    {key: "udi_dual_index_id"},
                    {key: "i7_index"},
                    {key: "i5_sequence"},
                    {key: "pcr_cycle"},
                    {key: "elution_vol"},
                    {key: "qubit"},
                    {key: "tapestation"},
                    {key: "tapestation_bp"},
                    {key: "lp_size"},
                    {key: "lp_nm"},
                    {key: "lp_dw"},
                    {key: "qc"},
                    {key: "tat"},
                    {key: "status"},
                    {key: "memo"},
                ];
                let data = [];
                for(let i=0;i<sample.list.length;i++) {
                    if(sample.list[i].sn=="") {
                        continue;
                    }
                    data.push({
                        "id" : sample.list[i].id,
                        "exp_date" : sample.list[i].exp_date,
                        "dna_batch_id" : sample.list[i].dna_batch_id,
                        "sn" : sample.list[i].sn,
                        "pooling_no" : sample.list[i].pooling_no,
                        "udi_plate" : sample.list[i].udi_plate,
                        "udi_dual_index_id" : sample.list[i].udi_well,
                        "i7_index" : sample.list[i].i7_index,
                        "i5_sequence" : sample.list[i].i5_sequence,
                        "pcr_cycle" : sample.list[i].pcr_cycle,
                        "elution_vol" : sample.list[i].elution_vol,
                        "qubit" : sample.list[i].qubit,
                        "tapestation" : sample.list[i].tapestation,
                        "tapestation_bp" : sample.list[i].tapestation_bp,
                        "lp_size" : sampleCalc.libraryPoolingSize(sample.list[i].tapestation_bp),
                        "lp_nm" : sampleCalc.libraryPoolingNM(sample.list[i].qubit, sample.list[i].tapestation_bp),
                        "lp_dw" : sampleCalc.libraryPoolingDW(sample.list[i].qubit, sample.list[i].tapestation_bp),
                        "qc" : sample.list[i].qc,
                        "tat" : sample.list[i].tat,
                        "status" : sample.list[i].status,
                        "memo" : sample.list[i].memo,
                    });
                }
                let params = {
                    info: {
                        exome_batch_id : comp.plateinfo.exome_batch_id,
                        nm: comp.plateinfo.nm,
                        pm: sampleCalc.libraryPoolingPM(),
                    },
                    schema: schema,
                    data: data,
                }
                axios({url:"/api/ExomeCapture/toXlsx", method:'POST', responseType: 'blob', data: params}).then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let today = new Date();
                    link.setAttribute('download', 'ExomeCapture_'+ comp.plateinfo.exome_batch_id +'_'+comp.dateToString(today)+'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                });
            },
            changeBatchID : () => {
                if(comp.id==0) {
                    toast.error("Exome Capture Batch 생성 후 변경 가능합니다.");
                    return;
                }
                let params = {
                    id: comp.id,
                    exome_batch_id: comp.plateinfo.exome_batch_id,
                    note: comp.plateinfo.note,
                }
                axios.post("/api/ExomeCapture/changeBatchID", params).then((res) => {
                    if(res.data.err == 0) {
                        toast.success("Exome Capture Batch ID가 변경되었습니다.");
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            changeBatchNm : () => {
                if(comp.id==0) {
                    toast.error("Exome Capture Batch 생성 후 변경 가능합니다.");
                    return;
                }
                let params = {
                    id: comp.plateinfo.id,
                    nm: comp.plateinfo.nm,
                }
                axios.post("/api/ExomeCapture/changeBatchNm", params).then((res) => {
                    if(res.data.err == 0) {
                        toast.success("Library Pooling nm 정보가 변경되었습니다.");
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            checkLibraryBatchID : (id_library_prep_sample) => {
                for(let i=0;i<sample.list.length;i++) {
                    if(sample.list[i].id_library_prep_sample==id_library_prep_sample) {
                        return true;
                    }
                }
                return false;
            },
            getLibraryBatchID : () => {
                let params = {
                    id: comp.plateinfo.id,
                    library_batch_id: comp.library_batch_id,
                }
                axios.post("/api/ExomeCapture/getLibraryBatchID", params).then((res) => {
                    if(res.data.err == 0) {
                        let key = 0;
                        for(let i=0;i<sample.list.length;i++) {
                            if(sample.list[i].sn == "") {
                                if(res.data.list.length>key) {
                                    if(!sample.checkLibraryBatchID(res.data.list[key].id_library_prep_sample)) {
                                        sample.list[i].exp_date = res.data.list[key].exp_date;
                                        sample.list[i].dna_batch_id = res.data.list[key].dna_batch_id;
                                        sample.list[i].sn = res.data.list[key].sn;
                                        sample.list[i].udi_plate = res.data.list[key].udi_plate;
                                        sample.list[i].udi_well = res.data.list[key].udi_well;
                                        sample.list[i].udi_dual_index_id = res.data.list[key].udi_dual_index_id;
                                        sample.list[i].i7_index = res.data.list[key].i7_index;
                                        sample.list[i].i5_sequence = res.data.list[key].i5_sequence;
                                        sample.list[i].qubit = res.data.list[key].qubit;
                                        sample.list[i].pcr_cycle = res.data.list[key].pcr_cycle;
                                        sample.list[i].tapestation = res.data.list[key].tapestation;
                                        sample.list[i].tapestation_bp = res.data.list[key].tapestation_bp;
                                        sample.list[i].id_library_prep_batch = res.data.list[key].id_library_prep_batch;
                                        sample.list[i].id_library_prep_sample = res.data.list[key].id_library_prep_sample;
                                        sample.list[i].id_dna_prep_batch = res.data.list[key].id_dna_prep_batch;
                                        sample.list[i].id_dna_prep_sample = res.data.list[key].id_dna_prep_sample;
                                    }

                                    key++;
                                } else {
                                    break;
                                }
                            }
                        }
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            initData: (data) => {
                data.sn = "";
                data.id_library_prep_batch = 0;
                data.id_library_prep_sample = 0;
                data.id_dna_prep_batch = 0;
                data.id_dna_prep_sample = 0;
                data.exp_date = "";
                data.sn = "";
                data.pooling_no = "";
                data.udi_plate = "";
                data.udi_well = "";
                data.udi_dual_index_id = "";
                data.i7_index = "";
                data.i5_sequence = "";
                data.pcr_cycle = "";
                data.elution_vol = "";
                data.qubit = "";
                data.tapestation = "";
                data.tapestation_bp = "";
                data.library_pooling_size = "";
                data.library_pooling_nm = "";
                data.library_pooling_dw = "";
                data.qc = "";
                data.status = "";
                data.memo = "";
                data.tat = "";
                data.dna_batch_id = "";
                data.id_member=store.state.id_member;
                return data;
            },
            saveAll: () => {
                    let params = {
                        id_member: store.state.id_member,
                        infoArray: sample.list
                    }
                    axios.post("/api/ExomeCapture/sampleUpdateAll", params).then((res) => {
                        if(res.data.err == 0) {
                            let err_count = 0;
                            let update_count = 0;
                            for(let i = 0; i < res.data.list.length; i++) {
                                if(res.data.list[i].err == 0) {
                                    let without = ["id", "id_exome_capture_batch", "well_no"]
                                    for(let n in res.data.list[i].info) {
                                        if(!without.includes(n)) {
                                            if(Object.prototype.hasOwnProperty.call(sample.list[i], n)) {
                                                sample.list[i][n] = res.data.info[n];
                                            }
                                        }
                                    }
                                    update_count++;
                                } else if(res.data.list[i].err == 203) {
                                    sample.list[i] = sample.initData(sample.list[i]);
                                    update_count++;
                                } else {
                                    err_count++;
                                }
                            }
                            if(err_count>0) {
                                toast.error("문제가 있는 데이터가 "+err_count+"건 있습니다.("+update_count+"건 업데이트)");
                            } else {
                                toast.success(update_count+"건의 데이터가 업데이트 되었습니다.");
                            }
                        }
                    });
            },
            moveNext: (el, id, do_save, data) => {
                if(do_save) {
                    let params = {
                        id_member: store.state.id_member,
                        barcode: data.sn,
                        info: data
                    }
                    axios.post("/api/ExomeCapture/sampleUpdate", params).then((res) => {
                        if(res.data.err == 0) {
                            let without = ["id", "id_exome_capture_batch", "well_no"]
                            for(let n in res.data.info) {
                                if(!without.includes(n)) {
                                    if(Object.prototype.hasOwnProperty.call(data, n)) {
                                        data[n] = res.data.info[n];
                                    }
                                }
                            }
                            toast.success("샘플 정보가 업데이트 되었습니다.", {timeout: 700});
                        } else {
                            toast.error(res.data.err_msg);
                            if(res.data.err==203) {
                                data = sample.initData(data);
                            }
                        }
                    });
                }
                if(typeof el.srcElement.dataset.next=='undefined' || el.srcElement.dataset.next==null) {
                    return;
                }
                let nextElementId = id + el.srcElement.dataset.next;
                let check = document.getElementById(nextElementId);
                if(check!==null) {
                    check.focus();
                }
            },
            movePrev: (el, id, do_save) => {
                if(do_save) {
                    console.info("Save");
                }
                if(typeof el.srcElement.dataset.prev=='undefined' || el.srcElement.dataset.prev==null || el.srcElement.dataset.prev=="") {
                    return;
                }
                let nextElementId = id + el.srcElement.dataset.prev;
                let check = document.getElementById(nextElementId);
                if(check!==null) {
                    check.focus();
                }
            },
        });

        const imageViewer = reactive({
            show: false,
            url: "",
            name: "",
            checkImage: (filename) => {
                let ext = filename.toUpperCase().substr(-4);
                let searchText = ".PNG.JPG.JPEG.GIF.BMP";
                if(searchText.indexOf(ext)>=0) {
                    return true;
                }
                return false;
            },
            doShow: (pic) => {
                if(imageViewer.checkImage(pic.file_path)) {
                    imageViewer.name = pic.file_name;
                    imageViewer.url = pic.file_path;
                    imageViewer.show = true;
                } else {
                    location.href=pic.file_path;
                }
            },
            doHide: () => {
                imageViewer.show = false;
            },
            doDown: (pic) => {
                location.href="/rest/api/ExomeCapture/fileDown?idx=" + pic.id;
            },
            doRemove: (pic) => {
                if(confirm("파일을 삭제하시겠습니까?")) {
                    let params = {
                        id_exome_capture_batch: comp.id,
                        id_exome_capture_batch_files: pic.id,
                        id_member: store.state.id_member
                    }
                    axios.post("/api/ExomeCapture/fileDelete", params).then((res) => {
                        if(res.data.err == 0) {
                            for(let i=0;i<sample.files.length;i++) {
                                if(sample.files[i].id == pic.id) {
                                    sample.files.splice(i,1);
                                }
                            }
                        } else {
                            toast.error(res.data.err_msg);
                        }
                    });

                }
            }

        });

        const comp = reactive({
            plateinfo: {
                id: "",
                exome_batch_id: "",
                nm: "2.25",
                row: "H",
                col: "12"
            },
            library_batch_id: "",
            dna_batch_id: "",
            imageFiles: [],
            plateSaved: false,
            hasImage: false,
            imageURL: "url(/resources/img/upload.png)",
            id:0,
            is_view: true,
            doDrag: false,
            fileReset: true,
            barcode: "",
            info: null,
            dateToString(pdate) {
                return (pdate == null?"":[pdate.getFullYear(),((pdate.getMonth() + 1)>9 ? '' : '0') + (pdate.getMonth() + 1),(pdate.getDate()>9 ? '' : '0') + pdate.getDate()].join(''));
            },
            dragOn: () => {
                comp.doDrag = true;
            },
            dragOff: () => {
                comp.doDrag = false;
            },
            uploadImage: () => {
                let formData = new FormData();
                formData.append("id", comp.id);
                formData.append("id_member", store.state.id_member);
                for(let i=0;i<gelImage.value.files.length;i++) {
                    formData.append("nfiles[]", gelImage.value.files[i]);
                }

                axios.post("/api/ExomeCapture/fileUpload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    if(res.data.err == 0) {
                        sample.files = res.data.files;
                        for(let i=0;i<sample.files.length;i++) {
                            let ext = sample.files[i].file_path.toUpperCase().substr(-4);
                            let searchText = ".PNG.JPG.JPEG.GIF.BMP";
                            if(searchText.indexOf(ext)>=0) {
                                sample.files[i].file_thumb = sample.files[i].file_path;
                            }
                        }
                        comp.dragOff();
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            setPlateInfo: () => {
                if(comp.plateinfo.exome_batch_id.trim()=='') {
                    toast.error("Exome Capture Batch ID를 입력해주세요.");
                    return;
                }
                comp.plateinfo.row = comp.plateinfo.row.toUpperCase();

                let cnt_row = comp.plateinfo.row.charCodeAt(0) - 'A'.charCodeAt(0);
                let cnt_col = comp.plateinfo.col;
                if(cnt_row<0 || cnt_row>25) {
                    toast.error("Row는 A~Z까지 알파벳으로 입력해주세요.");
                    return;
                }

                let sample_list = [];
                let id_before = null;
                for(let j=0;j<cnt_col;j++) {
                    for(let i=0;i<cnt_row+1;i++) {
                        let well_no = String.fromCharCode(65+i) + (j+1);
                        sample_list.push({id_after:null, id_before:id_before, sn_before:"", sn_after:"", well_no: well_no,  dna_conc:"", qc:"", status:""});
                        id_before = well_no;
                        if(sample_list.length>1) {
                            sample_list[sample_list.length-2].id_after = well_no;
                        }
                    }
                }
                let params = {
                    exome_batch_id: comp.plateinfo.exome_batch_id,
                    nm: comp.plateinfo.nm,
                    row: comp.plateinfo.row,
                    col: comp.plateinfo.col,
                    list: sample_list,
                    note: "",
                    id_member: store.state.id_member
                }
                axios.post("/api/ExomeCapture/make", params).then((res) => {
                    if(res.data.err == 0) {
                        comp.plateSaved = true;
                        router.push({path: "/ExomeCapture/"+res.data.library_batch_id});

                        sample.list = [];
                        comp.id = res.data.library_batch_id;
                        comp.getInfo();

                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            getInfo: () => {
                let params = {
                    tmr: new Date(),
                }
                axios.get("/api/ExomeCapture/info/"+ Math.round(comp.id), {params : params}).then((res) => {
                    if(res.data.err == 0) {
                        comp.plateinfo = res.data.library_batch_info;
                        sample.list = res.data.list;
                        sample.files = res.data.files;
                        for(let i=0;i<sample.files.length;i++) {
                            let ext = sample.files[i].file_path.toUpperCase().substr(-4);
                            let searchText = ".PNG.JPG.JPEG.GIF.BMP";
                            if(searchText.indexOf(ext)>=0) {
                                sample.files[i].file_thumb = sample.files[i].file_path;
                            }
                        }
                        comp.plateSaved = true;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
        });
        onMounted(() => {
            sample.list = [];
            options.status = genoplan_status.ExomeCapture;

            if(route.params.id=="new") {
                comp.id = 0;
                comp.is_view = false;
                comp.info = {};
            } else {
                comp.id = route.params.id;
                if(comp.id==="") {
                    router.replace({"path":"/ExomeCapture/list"});
                } else {
                    comp.getInfo();
                }
            }
        });
        return {
            comp,
            options,
            sample,
            sampleCalc,
            gelImage,
            gelImagePreview,
            imageViewer,
            xlsFile
        };
    },
};
